@font-face {
  font-family: "Cinzel";
  src: local("Cinzel"), url(../fonts/cinzel/cinzel.ttf) format("ttf");
}
@font-face {
  font-family: "Cinzel-Black";
  src: local("CinzelBlack"), url(../fonts/cinzel/cinzelblack.ttf) format("ttf");
}
@font-face {
  font-family: "Cinzel-Black";
  src: local("CinzelBlack"),
    url(../fonts/cinzel/cinzelblack.woff) format("woff");
}
@font-face {
  font-family: "Cinzel-Black";
  src: local("CinzelBlack"),
    url(../fonts/cinzel/cinzelblack.woff2) format("woff");
}
@font-face {
  font-family: "Cinzel";
  src: local("Cinzel"), url(../fonts/cinzel/cinzel.woff) format("woff");
}
@font-face {
  font-family: "Cinzel";
  src: local("Cinzel"), url(../fonts/cinzel/cinzel.woff2) format("woff");
}
@font-face {
  font-family: "Cinzel-Bold";
  src: local("CinzelBold"), url(../fonts/cinzel/cinzelbold.ttf) format("ttf");
}
@font-face {
  font-family: "Cinzel-Bold";
  src: local("CinzelBold"), url(../fonts/cinzel/cinzelbold.woff) format("woff");
}
@font-face {
  font-family: "Cinzel-Bold";
  src: local("CinzelBold"), url(../fonts/cinzel/cinzelbold.woff2) format("woff");
}
