.home-page {
  .wrapper-hero {
    position: static;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 180px 0 300px;
    height: 100vh;

    .logo {
      background-image: $main_page-logo_main-min;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 457px;
      height: 300px;
      display: none;
    }

    .hero {
      background-image: $main_page-paralax_bg-min;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      position: fixed;
      top: 0;
      z-index: -1;
      animation-name: fadeIn;
      animation-duration: 1.5s;
      animation-delay: 0.2s;
      animation-fill-mode: forwards;
      width: 100vw; 
      height: 100vh;
    }

    .trailer-button {
      color: white;
      border: gray;
      background-color: black;
      padding: 20px;
      border: solid #adadad 2px;
    }
    .trailer-button:hover {
      background-color: gray;
    }

    .home-page-description {
      padding-top: 25vh;
    }
    
    .youtube {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 20vh;
      padding-bottom: 56.25%;
    }

    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

  }
  .sec-email {
    background-color: black;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .wrapper {
      flex-direction: column;
      justify-content: center;
      padding: 70px 0 70px;
      text-align: center;
      p {
        padding: 0 60px;
      }
    }
    &__heading {
      font-size: 36px;
      font-weight: 800;
      max-width: 319px;
      margin-bottom: 0;
    }
    &__subheading {
      max-width: 525px;
      margin: 35px 0;
    }
    &__content {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap-reverse;
      margin-top: 30px;
      
    }
  }

  .sec-2 {
    background-color: black;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .wrapper {
      flex-direction: column;
      justify-content: center;
      padding: 70px 0 140px;
      text-align: center;
      p {
        padding: 0 60px;
      }
    }
    &__heading {
      font-size: 36px;
      font-weight: 800;
      max-width: 319px;
      margin-bottom: 0;
    }
    &__subheading {
      max-width: 525px;
      margin: 35px 0;
    }
    &__content {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap-reverse;
      margin-top: 30px;
      
    }
  }
  .sec-3 {
    background-color: black;
    background-image: url("/assets/images/main_page/background-transparent-gradient.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .wrapper {
      flex-direction: column;
      justify-content: center;
      padding: 70px 0 140px;
      text-align: left;
      p {
        padding: 0 60px;
      }
      .row .column ul li {
        padding: 0 60px;
	list-style-position: inside;
      }
    }
    &__heading {
      font-size: 36px;
      font-weight: 800;
      max-width: 319px;
      margin-bottom: 0;
    }
    &__subheading {
      max-width: 525px;
      margin: 35px 0;
    }
    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
    }
    .column {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
      justify-content: left;
      align-items: left;
      ul.features, li {
        list-style-type: square;
        margin-bottom: 1em;        
      }
    }
  }
}

.sec-4 {
  background-image: $main_page-game_screen_bg-min;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 90px 0 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  .sec-4__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  &__right {
      background-image: url("/assets/images/main_page/frame_game_screen_01-min.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 524px;
      height: 324px;
  }
}

.game-screen-1 {
  background-image: url("/assets/images/main_page/game_screen_01-min.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 482px;
  height: 282px;
  margin: 21px auto;
}

.hero-avatar__border--big img {
  height: 321px;
}

.modal-container
{
  display:flex;
  justify-content:center;
  align-items:center;
  top: 0;
  left:0;
  right:0;
  bottom:0;
  position:absolute;
}

.modal-container h1, .email-container h1
{
  z-index:10;
  background-color: black;
  border: 0.1px solid gray;
  padding: 20px 10px;
  cursor: pointer;
}

.modal-container h1
{
  margin-top: 75vh;
}

.modal-container h1 a, .email-container h1 a{
   color:white;
}

.modal-container h1 img {
  margin: 10px 5px 5px 5px;
}

#modal{
  visibiliy:hidden;
  opacity:0;
  position:absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
  z-index:1;
  display:flex;
  justify-content:center;
  align-items:center;
  transition: .3s opacity ease-in-out;
}

#modal:target{
  visibility:visible;
  opacity:1;
  z-index:11;
}

#modal .close{
  position:absolute;
  width:100%;
  height:100%;
  cursor:default;
  
}

#modal .modal-content{
  padding:10px;
  display:flex;
  position:relative;
  justify-content:center;
  align-items:center;
  width:80%;
  height: 80vh;
  background-color:rgba(0,0,0,.8);
}

#modal .modal-content iframe{
  height: 80%;
  width: 100%;
}

#modal .modal-content a{
  position: absolute;
  color:white;
  font-size:1.5rem;
  top:0;
  right:10px;
}

.email-container input {
  padding: 1.4rem 2rem;
}

.grym-label, .tarin-label, .ilek-label, .zenshi-label, .rayla-label, .rayla-label, .drglop-label {
  flex: 1 1 auto;
}

.roster-images {
  width: 100%;
}

.roster-images div {
  display: inline-block;
  position: relative;
}

.roster-images img {
  transition: all .2s ease-in-out; 
}

.roster-images img:hover,
#grym-label:hover~#grym-avatar,
#tarin-label:hover~#tarin-avatar,
#ilek-label:hover~#ilek-avatar,
#zenshi-label:hover~#zenshi-avatar,
#rayla-label:hover~#rayla-avatar,
#drglop-label:hover~#drglop-avatar
{
  transform: scale(1.1) translateY(-4.5%); 
}

.roster-images a {
  line-height: 100px;
}

.grym-image a, .ilek-image a {
}

.roster-images b {
  line-height: 100px;
  color: #fff6dd;
  text-transform: uppercase;
  padding: 10px;
}

.roster-images b:hover,
#grym-avatar:hover~#grym-label,
#tarin-avatar:hover~#tarin-label,
#ilek-avatar:hover~#ilek-label,
#zenshi-avatar:hover~#zenshi-label,
#rayla-avatar:hover~#rayla-label,
#drglop-avatar:hover~#drglop-label
{
  border: solid 0.1px tan;
}

.grym-image {
  width: 350px;
  z-index: 4;
}

.grym-image img {
  width: 350px;
  margin-bottom: 20px;
  margin-left: -260px;
}

.grym-image b {
  margin-right: 200px;
}

.tarin-image {
  width: 300px;
  margin-left: -220px;
  z-index: 5;
}

.tarin-image img {
  width: 300px;
} 

.ilek-image {
  width: 450px;
  margin-left: -180px;
  z-index: 4;
}

.ilek-image img {
  width: 450px;
}

.zenshi-image {
  width: 460px;
  margin-left: -160px;
  z-index: 4;
}

.zenshi-image img {
  width: 460px;
}

.zenshi-image b {
  margin-right: 200px;
}

.rayla-image {
  width: 320px;
  margin-left: -350px;
  z-index: 5;
}

.rayla-image img {
  margin-bottom: 100px;
  width: 320px;
}


.drglop-image {
  width: 260px;
  margin-left: -200px;
  z-index: 6;
}

.drglop-image img {
  width: 260px;
  margin-bottom: -50px;
}

.hero-avatar {
  display: none;
}

.launch-date {
  background-color: black;
  font-weight: bold;
  text-align: center;
}
