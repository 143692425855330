.footer {
  background-image: url("/assets/images/main_page/footer-min.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 35px 60px;

  &__inner {
    margin: auto;
    display: flex;
    justify-content: space-between;

    .left-side {
      display: flex;
      justify-content: center;
      align-items: center;
      .footer__logo2 {
        margin-left: 35px;
      }
    }

    .right-side {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      .right-side__buttons {
        img:first-child {
          margin-right: 25px;
        }
      }

      &__texts {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 35px;

        a {
          margin-right: 30px;
        }
      }
    }
  }
}
