.header {
  background-image: $main_page-menu_bar-min;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 515px;
  height: 89px;
  font-family: "Cinzel-Bold";
  margin: 0 auto;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  &__wrapper {
    padding: 5px;
    height: 79px;
    width: 495px;
    border-radius: 0 0 10px 10px;
    box-shadow: inset 0 0px 60px 0px $black;
  }
  &__inner {
    display: flex;

    .menu {
      width: 100%;
      margin: 0;
      list-style-type: none;
      display: flex;
      justify-content: space-around;
      margin-left: 30px;

      &__link {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &__text {
        color: $header-text;
        font-weight: bold;
        font-size: 12px;
        margin-top: 8px;
      }
    }
  }
}
