  .sec-2 {
    background-color: black;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .wrapper {
      flex-direction: column;
      justify-content: center;
      padding: 70px 0 140px;
      text-align: center;
      p {
        padding: 0 60px;
      }
    }
    &__heading {
      font-size: 36px;
      font-weight: 800;
      max-width: 319px;
      margin-bottom: 0;
    }
    &__subheading {
      max-width: 525px;
      margin: 35px 0;
    }
    &__content {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap-reverse;
      margin-top: 30px;
      
    }
  }

.single-hero-page {
  .hero-name-section {
    background-image: $heroes_personal_page-hero_name_bg-min;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    padding: 0px 0 120px;
    .wrapper {
      justify-content: center;
    }
  }
  .tarin-section {
    background: linear-gradient(
                  rgba(20,20,20, .8), 
                  rgba(20,20,20, .8)),
                $heroes_personal_page-tarin_bg-min;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    padding: 40px 0 120px;
    z-index: 1;
    .wrapper {
      justify-content: center;
      padding: 0 60px 20px 60px;
    }
    .hero-banner {
      width: 100%;
      padding-bottom: 40px;
      &__image {
        background-image: $heroes_personal_page-tarin_bg-min;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: 80vw;
	height: 75vh;
        margin: 0 auto;
      }
    }
  }
  .zenshi-section {
    background: linear-gradient(
                  rgba(20,20,20, .8), 
                  rgba(20,20,20, .8)),
                $heroes_personal_page-zenshi_bg-min;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    padding: 40px 0 120px;
    z-index: 1;
    .wrapper {
      justify-content: center;
      padding: 0 60px 20px 60px;
    }
    .hero-banner {
      width: 100%;
      padding-bottom: 40px;
      &__image {
        background-image: $heroes_personal_page-zenshi_bg-min;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: 80vw;
	height: 75vh;
        margin: 0 auto;
      }
    }
  }
  .grym-section {
    background: linear-gradient(
                  rgba(20,20,20, .8), 
                  rgba(20,20,20, .8)),
                $heroes_personal_page-grym_bg-min;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    padding: 40px 0 120px;
    z-index: 1;
    .wrapper {
      justify-content: center;
      padding: 0 60px 20px 60px;
    }
    .hero-banner {
      width: 100%;
      padding-bottom: 40px;
      &__image {
        background-image: $heroes_personal_page-grym_bg-min;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: 80vw;
	height: 75vh;
        margin: 0 auto;
      }
    }
  }
  .hero-story-section {
    background-image: $heroes_personal_page-default_bg-min;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .top-part {
        width: 100%;
        margin: 60px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .hero-banner {
          background-image: $heroes_personal_page-frame_game_screen-min;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          width: 524px;
          height: 324px;
          padding: 12px;
          &__image {
            background-image: $heroes_personal_page-grym_bg-min;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            width: 100%;
            height: 100%;
          }
        }
        .abilities-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .abilities-row {
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            padding: 0 20px;
            .ability {
              background-image: url("/assets/images/heroes_personal_page/skils_frame-min.png");
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;
              width: 113px;
              height: 114px;
              cursor: pointer;
              padding: 3px;
              &__image {
                width: 100%;
                height: 100%;
              }
              .pop-up {
                display: none;
                & p:first-child {
                  font-weight: 800;
                  margin-bottom: 10px;
                }
                p {
                  margin: 0;
                }
              }
              &:hover .pop-up {
                display: flex;
                flex-direction: column;
                justify-content: center;
                max-width: 300px;
                background-color: #b3b2b2;
                transform: translate(-20px);
                position: absolute;
                color: $white;
                padding: 15px;
              }
            }
          }
        }
      }
      .bottom-part {
        margin-bottom: 70px;
        .text:first-child {
          text-align: left;
        }
      }
    }
  }
  .select-hero-section {
    background-color: #111;
    padding-bottom: 70px;
    .wrapper {
      .select-hero-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
          margin: 50px 0;
          font-weight: 800;
        }
      }
    }
  }
}
