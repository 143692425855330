html,
body {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Open Sans", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  color: $text-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.home-button {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
}

.navigation-image {
  width: 60px;
}


#main-container {
  line-height: 25px;
}

// Preloader
.loading {
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
  background-color: black;
  border-radius: 100%;
  height: 6em;
  width: 6em;
}

#spinner {
  align-items: center;
  background-color: white;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  z-index: 100000;
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
    transform: scale(1);
  }
}

// Global styles
.half {
  width: 50%;
  display: flex;
  justify-content: center;
}

.button {
  width: 133px;
  height: 40px;
}

.hero-avatar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &__border {
    background-image: url("/assets/images/main_page/frame_hero-min.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 16px 16px 0;
    width: 164px;
    height: 164px;
    padding: 3px;

    &--big {
      background-image: $heroes_page-hero_icon-min;
      margin: 0 20px 20px 0;
      width: 339px;
      height: 338px;
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    position: relative;
    &--disabled {
      position: absolute;
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1100px;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0;
}

// text
.text {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  max-width: 348px;
  color: $text-color;
  text-shadow: 0 0 10px #000000, 0 0 10px #1c1b1b;

  // size
  &--size-l {
    font-size: 36px;
    line-height: 49px;
  }
  &--size-m {
    font-size: 24px;
    line-height: 33px;
  }
  &--size-s {
    font-size: 18px;
    line-height: 25px;
  }

  // width
  &--width-l {
    max-width: 618px;
  }
  &--width-m {
    max-width: 514px;
  }
  &--width-heading {
    max-width: 270px;
  }
  &--width-s {
    max-width: 180px;
  }

  // weight
  &--weight-b {
    font-weight: 800;
  }
  &--weight-m {
    font-weight: 700;
  }
  &--weight-s {
    font-weight: 600;
  }

  //margins
  &--margin-15 {
    margin: 15px 0;
  }
  &--margin-30 {
    margin: 30px 0;
  }
  &--margin-b-30 {
    margin-bottom: 30px;
  }
  &--margin-b-15 {
    margin-bottom: 15px;
  }

  // shadow
  &--shadow {
    text-shadow: 0 0 10px #000000, 0 0 10px #1c1b1b;
  }
  &--shadow-none {
    text-shadow: none;
  }

  // align
  &--align-left {
    text-align: left;
  }
}
