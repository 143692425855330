.heroes-page {
  background-image: $heroes_page-heroes_bg-min;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  .wrapper {
    flex-direction: column;
    text-align: center;
    p:first-child {
      margin: 113px 0 10px;
    }
  }
}

.hero-icon {
  background-image: $heroes_page-hero_icon-min;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
}

.abilities {
  background-color: black;
  border: solid goldenrod 1px;
}

.ability-line {
  display: grid;
  grid-template-columns: 40vw 10vw;
  align-items: center;
  column-gap: 40px;
}

.ability-image {
  grid-column: 2;
  grid-row: 1/1;
  width: 120px;
}

.ability-description {
  grid-column: 1;
  grid-row: 1/1;
}
