@media screen and (max-width: 1240px) {
  .home-page {
    .sec-2 {
      .wrapper {
        width: 650px;
      }

      &__content {
        flex-direction: column-reverse;
        align-items: center;
        
      }
    }

    .sec-3 {
      &__left {
        margin-bottom: 30px;
      }
    }
  }

  .about-page {
    .about-section__content {
      .blocks-container {
        .block {
          flex-direction: column;
          align-items: center;

          &:first-child {
            flex-direction: column-reverse;
          }
        }
      }
    }
  }

  .wrapper {
    width: 90%;
    flex-direction: column;
  }

  .half {
    width: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .single-hero-page {
    .hero-name-section {
      background-image: $tablet-heroes_personal_page-hero_name_bg-min;
    }
    .hero-story-section {
      background-image: $tablet-heroes_personal_page-default_bg-min;
      .wrapper {
        .top-part {
          flex-direction: column;
          margin: 60px 0 20px;
          .abilities-container {
            .abilities-row {
              justify-content: center;
              .ability {
                margin-right: 20px;
              }
            }
          }
        }
      }
    }
    .select-hero-section {
      background-image: url("/assets/images/tablet/heroes_personal_page/heroes_bg-min.jpg");
    }
  }

  .lore-page {
    .section-3 {
      .wrapper {
        width: 650px;
      }
    }
  }

  .footer {
    background-image: url("/assets/images/tablet/main_page/footer-min.jpg");
    padding: 40px 0 47px;

    &__inner {
      flex-direction: column;

      .left-side {
        margin-bottom: 45px;
      }
      .right-side {
        align-items: center;

        &__texts {
          margin-top: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  // ### pages ###
  .home-page {
    .wrapper-hero {
      padding: 180px 0 295px;

      .logo {
        display: block;
	padding: 100px;
      }

      .hero {
        background-image: $tablet-main_page-paralax_bg-min;
      }
    }
    .sec-2 {
      background-color: black;
    }

    .sec-3 {
      background-image: url("/assets/images/tablet/main_page/concept_bg_03-min.jpg");
    }

    .sec-4 {
      background-image: url("/assets/images/tablet/main_page/game_screen_bg-min.jpg");
    }
  }

  .heroes-page {
    background-image: $tablet-heroes_page-heroes_bg-min;
    .wrapper {
      width: 91%;
    }
    .hero-avatar {
      li:nth-child(2n) {
        .hero-avatar__border--big {
          margin-right: 0;
        }
      }
    }
  }

  .lore-page {
    .section {
      &-1 {
        background-image: $tablet-lore_page-concept_bg_02-min;
      }

      &-2 {
        background-image: url("/assets/images/tablet/lore_page/concept_bg_03-min.jpg");
      }
      &-3 {
        background-image: url("/assets/images/tablet/lore_page/default_bg-min.jpg");

        .wrapper {
          width: 90%;
        }
      }
    }
    .wrapper {
      width: 60%;
    }
  }

  .about-page {
    .logo-section {
      background-image: $tablet-about_page-about_illustration_01-min;
    }
    .about-section {
      &__content {
        .blocks-container {
          .block {
            flex-direction: column;
          }
        }
      }
    }
  }

  // ### parts ###
  .wrapper {
    // width: 525px;
    width: 90%;
    flex-direction: column;
  }

  .hero-avatar {
    li,
    li:nth-child(3n) {
      .hero-avatar__border {
        margin: 5px;
      }
    }
  }

  .grym-image, .tarin-image, .ilek-image, .zenshi-image, .rayla-image, .drglop-image,
  .grym-image img, .tarin-image img, .ilek-image img, .zenshi-image img, .rayla-image img, .drglop-image img,
  .grym-image b, .tarin-image b, .ilek-image b, .zenshi-image b, .rayla-image b, .drglop-image b {
    margin: 0 auto;
    width: 300px;
  }
}

@media screen and (max-width: 650px) {
  .home-page {

    .sec-2 {
      .wrapper {
        width: 100%;
      }
    }
  }

  .single-hero-page {
    .hero-name-section {
      background-image: $mobile-heroes_personal_page-hero_name_bg-min;
      background-position: initial;
    }
    .hero-story-section {
      background-image: url("/assets/images/mobile/heroes_personal_page/default_bg-min.jpg");
      background-position: initial;
      .wrapper {
        width: 90%;
        .top-part {
          flex-direction: column;
          margin: 60px 0 20px;

          .hero-banner {
            background-image: $mobile-heroes_personal_page-frame_game_screen-min;
            width: 324px;
            height: 206px;
          }

          .abilities-container {
            .abilities-row {
              justify-content: center;
              flex-wrap: wrap;
              width: 330px;
              .ability {
                margin-right: 20px;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
    .select-hero-section {
      background-image: $mobile-heroes_personal_page-heroes_bg-min;
    }
  }

  .about-page {
    .text--width-l {
      max-width: 255px;
      font-size: 18px;
      line-height: 22px;
    }

    .logo-section {
      background-image: $mobile-about_page-about_illustration_01-min;
    }
    .about-section {
      &__content {
        .blocks-container {
          .block {
            &__text {
              background-image: url("/assets/images/mobile/about_page/frame_text_1-min.png");
              width: 242px;
              height: 298px;
              padding: 15px;

              .text {
                margin-bottom: 20px;
                font-size: 13px;
                line-height: 20px;
              }
            }
            &__image {
              background-image: url("/assets/images/mobile/about_page/frame-min.png");

              width: 240px;
              height: 183px;

              &__content-1 {
                background-image: url("/assets/images/mobile/about_page/about_illustration_02-min.jpg");
                width: 206px;
                height: 149px;
              }

              &__content-2 {
                background-image: url("/assets/images/mobile/about_page/about_illustration_03-min.jpg");
                width: 206px;
                height: 149px;
              }
            }
          }
        }
      }
      .email {
        background-image: url("/assets/images/mobile/about_page/frame_text_2-min.png");
        width: 241px;
        height: 176px;
        flex-wrap: wrap;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .home-page {
    .wrapper-hero {
      .logo {
	display: block;
        width: 355px;
        height: 245px;
      }
    }

    .sec-3 {
      .row {
        display: block;
      }

      &__left {
        background-image: url("/assets/images/main_page/frame_game_screen_01-min.png");
        width: 324px;
        height: 207px;
      }
    }
  }

  .heroes-page {
    background-image: $mobile-heroes_page-heroes_bg-min;
    background-position: initial;
    .hero-avatar {
      li {
        .hero-avatar__border--big {
          margin: 0 0 25px;
        }
      }
    }
    .wrapper {
      width: 335px;
    }
  }

  .lore-page {
    .lore-concept {
      background-image: url("/assets/images/mobile/lore_page/frame-min.png");
      width: 324px;
      height: 206px;
      padding: 23px;
      margin: 15px 0;

      &__image {
        background-image: url("/assets/images/mobile/lore_page/lore_concept-min.jpg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        width: 282px;
        height: 158px;
      }
    }

    .section {
      &-1 {
        background-image: $mobile-lore_page-concept_bg_02-min;
      }

      &-2 {
        background-image: url("/assets/images/mobile/lore_page/concept_bg_03-min.jpg");
      }
      &-3 {
        background-image: url("/assets/images/mobile/lore_page/default_bg-min.jpg");

        .wrapper {
          width: 100%;
        }
      }
    }

    .wrapper {
      width: 90%;
    }
  }

  .privacy-page {
    padding: 120px 0 220px;

    .wrapper {
      width: 330px;
    }
  }

  // ### parts ###
  .game-screen-1 {
    background-image: url("/assets/images/mobile/main_page/game_screen_01-min.jpg");
    width: 282px;
    height: 164px;
  }

  .header {
    background-image: $mobile-main_page-menu_bar-min;
    width: 360px;
    height: 88px;

    .header__inner {
      .menu {
        margin-left: 5px;
      }
    }

    &__wrapper {
      width: 345px;
      height: 78px;
    }

    &__logo {
      img {
        width: 65px;
        height: 65px;
      }
    }
  }

  .footer {
    background-image: url("/assets/images/mobile/main_page/footer-min.jpg");

    &__inner {
      flex-direction: column;

      .left-side {
        flex-direction: column;
        align-items: center;

        .footer__logo1 {
          width: 150px;
          height: 80px;
        }

        .footer__logo2 {
          width: 96px;
          height: 60px;
          margin: 0;
        }
      }

      .right-side {
        &__texts {
          flex-direction: column;

          a {
            margin: 0;
          }
        }
      }
    }
  }

  .wrapper {
    width: 100%;
  }

  .button {
    width: 100px;
    height: 30px;
  }
}

@media screen and (max-width: 359px) {
  // pages
  .home-page {
    .wrapper-hero {
      .logo {
        width: 300px;
        height: 200px;
	display: block;
      }
    }

    .sec-3 {
      &__left {
        width: 300px;
        height: 189px;

        .game-screen-1 {
          margin: 12px auto;
          width: 280px;
          height: 164px;
        }
      }
    }
  }

  .heroes-page {
    .hero-avatar {
      li {
        .hero-avatar__border--big {
          margin: 0 0 25px;
          width: 300px;
          height: 300px;
          padding: 7px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .wrapper {
      width: 91%;
    }
  }

  .single-hero-page {
    .hero-story-section {
      .wrapper {
        .top-part {
          .hero-banner {
            width: 300px;
            height: 189px;
          }
          .abilities-container {
            .abilities-row {
              width: 300px;
              padding: 0;
            }
          }
        }
      }
    }
  }

  .lore-page {
    .lore-concept {
      width: 300px;
      height: 190px;
      padding: 20px;

      &__image {
        width: 100%;
        height: 100%;
      }
    }

    .section {
      &__content {
        width: 100%;
      }
    }
  }

  .privacy-page {
    .wrapper {
      width: 100%;
    }

    &__content {
      width: 90%;
    }
  }

  // components
  .game-screen-1 {
    width: 260px;
    height: 150px;
  }

  .header {
    .header__inner {
      .menu {
        margin: 0;
      }
    }
    width: 100%;
    height: 78px;

    &__wrapper {
      width: 97%;
      height: 70px;
    }

    &__logo {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
