.privacy-page {
  background-image: $privacy_terms_page-default_bg-min;
  background-repeat: no-repeat;
  background-size: cover;

  padding: 120px 0 295px;
  color: $white;
  line-height: 22px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: left;

    .text {
      text-align: start;
    }
    .text-header {
      text-align: center;
    }
  }
  .wrapper {
    flex-direction: column;
    width: 500px;
  }
}

.privacy-page li {
  list-style-position: inside;
  list-style-type: square;
}
