.lore-page {
  .wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 36%;
  }

  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &-1 {
      background-image: $lore_page-concept_bg_02-min;
    }

    &-2 {
      background-image: url("/assets/images/lore_page/concept_bg_03-min.jpg");
    }

    &-3 {
      background-image: url("/assets/images/lore_page/default_bg-min.jpg");
      padding: 20px 0;

      .wrapper {
        width: 100%;
      }
    }

    .section-heading {
      margin: 150px 0;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__the-present {
      background-image: $lore_page-concept_bg_02-min;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 155px;
    }
  }

  .lore-concept {
    background-image: $lore_page-frame-min;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 23px;
    margin: 15px 0;

    &__image {
      background-image: $lore_page-lore_concept-min;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      width: 481px;
      height: 303px;
    }
  }
}
