.about-page {
  .logo-section {
    background-image: $about_page-about_illustration_01-min;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 220px 0 185px;

    .text {
      margin-top: 45px;
    }
  }

  .about-section {
    background-image: $about_page-default_bg-min;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 85px 0 60px;
    font-size: 18px;

    .wrapper {
      justify-content: center;
    }

    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > .text {
        margin-bottom: 75px;
      }

      .blocks-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $white;

        .block {
          width: 100%;
          display: flex;
          margin-bottom: 60px;

          &__text {
            background-image: $about_page-frame_text_1-min;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 45px;
            width: 532px;

            .text {
              margin-bottom: 35px;
              text-align: start;
            }
          }

          &__image {
            background-image: $about_page-frame-min;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 23px;
            width: 532px;
            height: 396px;

            &__content-1 {
              background-image: $about_page-about_illustration_02-min;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
              width: 490px;
              height: 354px;
            }
            &__content-2 {
              background-image: url("/assets/images/about_page/about_illustration_03-min.jpg");
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
              width: 490px;
              height: 354px;
            }
          }
        }
      }

      .email {
        background-image: url("/assets/images/about_page/frame_text_2-min.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 613px;
        height: 145px;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;

        &__half {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 15px 35px;
        }
      }
    }
  }
}
